import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'react-i18next';

const Hero = () => {
  const { t } = useTranslation();
  const { title, description } = t('hero', { returnObjects: true });
  return (
    <div className="pt-218-px-lg pt-100-px relative">
      <div className="py-20-xxl py-12 w-90-lg px-6 w-full mx-auto">
        <StaticImage
          src="../../../images/about-us/hero-bg.jpg"
          quality={100}
          layout="constrained"
          className="absolute top-0 left-0 h-full w-full object-cover -z-10"
          alt="Hero background"
        />
        <div className="absolute top-0 left-0 h-full w-full object-cover -z-10 gradient-blue-62" />
        <div className=" w-full max-w-1400 mx-auto pb-12">
          <h1 className="font-bold text-5xl-xxl text-4xl-lg text-3xl text-white text-center max-w-1000-xl max-w-700 mx-auto mb-8 w-50-lg">
            {title}
          </h1>
          <p className="font-light text-xl-xxl text-lg-lg text-base text-white max-w-700 mx-auto text-center">
            {description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Hero;
