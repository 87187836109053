import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Mission = () => {
  const { t } = useTranslation();
  const {
    title,
    firstSubTitle,
    firstDescription,
    secondSubTitle,
    secondDescription,
  } = t('mission', { returnObjects: true });
  return (
    <div className="py-20-xxl py-12-lg pb-12 pt-8 container px-6 w-90-lg w-full mx-auto">
      <div className="grid grid-cols-2-lg place-items-center max-w-1200 mx-auto pt-12-lg px-8-lg">
        <div className="w-90 h-full relative me-auto order-2 order-1-lg mt-0-lg mt-12">
          <div className="h-75 w-75 border-4 border-blue-400 absolute -top-7 -right-10" />
          <StaticImage
            src="../../../images/about-us/mission.png"
            layout="constrained"
            quality={100}
            objectFit="cover"
            className="h-full w-full"
            alt="Mission image"
          />
        </div>

        <div className="flex flex-col justify-start items-start w-90-lg ms-auto mt-8 mt-0-lg order-1 order-2-lg">
          <h3 className="font-bold text-blue-900 text-5xl-xxl text-4xl-lg text-3xl mb-6">
            {title}
          </h3>
          <span className="font-bold text-black text-lg-xxl text-base mb-2">
            {firstSubTitle}
          </span>
          <p className="font-light text-lg-xxl text-base text-blue-800 mb-6">
            {firstDescription}
          </p>

          <span className="font-bold text-black text-lg-xxl text-base mb-2">
            {secondSubTitle}
          </span>
          <p className="font-light text-lg-xxl text-base text-blue-800 mb-6">
            {secondDescription}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Mission;
