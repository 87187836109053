import React from 'react';
import PropTypes from 'prop-types';
import PageLayout from '../shared/PageLayout';
import Hero from '../page-components/aboutUs/Hero';
import Products from '../page-components/aboutUs/Products';
import Presentation from '../page-components/aboutUs/Presentation';
import Mission from '../page-components/aboutUs/Mission';
import CTASection from '../page-components/homePage/CTA';
import { graphql } from 'gatsby';
import axios from '../api/axios';

const aboutUs = ({ serverData }) => {
  const { solutions } = serverData;
  return (
    <PageLayout solutions={solutions}>
      <Hero />
      <Presentation />
      <Mission />
      <Products list={solutions} />
      <CTASection />
    </PageLayout>
  );
};
aboutUs.propTypes = {
  serverData: PropTypes.object,
};

export default aboutUs;

export async function getServerData() {
  try {
    const { data: solutionData } = await axios.get('/api/solutions', {
      params: {
        fields: ['name', 'slug'],
        populate: {
          products: {
            fields: ['name', 'slug'],
            populate: {
              product_types: {
                fields: ['name', 'slug'],
                populate: {
                  product_items: {
                    fields: ['name', 'slug'],
                  },
                },
              },
            },
          },
        },
      },
    });

    return {
      props: {
        solutions: solutionData?.data.map((item) => ({
          title: item?.attributes?.name,
          url: item?.attributes?.slug,
          subMenu: item?.attributes?.products?.data?.map((subItem) => ({
            title: subItem?.attributes?.name,
            url: subItem?.attributes?.slug,
            subMenu: subItem?.attributes?.product_types?.data?.map(
              (subSubItem) => ({
                title: subSubItem?.attributes?.name,
                url: subSubItem?.attributes?.slug,
              }),
            ),
          })),
        })),
      },
    };
  } catch (error) {
    return {
      status: 500,
      headers: {},
      props: {},
    };
  }
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["AboutUs", "Layout"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
