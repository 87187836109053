import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'react-i18next';

const Presentation = () => {
  const { t } = useTranslation();
  const { label, title, paragraphs } = t('presentation', {
    returnObjects: true,
  });
  return (
    <div className="py-20-xxl py-12-lg py-8 container px-6 w-90-lg w-full mx-auto">
      <div className="grid grid-cols-2-lg place-items-center max-w-1200 mx-auto pt-12-lg px-8-lg">
        <div className="flex flex-col justify-start items-start w-90-lg me-auto">
          <span className="text-2xl-xxl text-xl-lg text-lg text-blue-400 font-medium mb-4-lg">
            {label}
          </span>
          <h3 className="font-bold text-blue-900 text-5xl-xxl text-4xl-lg text-3xl mb-6-lg mb-4 w-90">
            {title}
          </h3>
          <p className="font-light text-blue-800 text-xl-xxl text-base mb-8-lg mb-6">
            {paragraphs.map((p, index) => (
              <>
                {p}
                {index < paragraphs.length - 1 && (
                  <>
                    <br />
                    <br />
                  </>
                )}
              </>
            ))}
          </p>
        </div>
        <div className="w-90 h-full relative me-auto mt-0-lg mt-12">
          <div className="h-75 w-75 border-4 border-blue-400 absolute -top-7 -right-10" />
          <StaticImage
            src="../../../images/about-us/presentation.jpeg"
            layout="constrained"
            quality={100}
            objectFit="cover"
            className="h-full w-full"
            alt="Presentation image"
          />
        </div>
      </div>
    </div>
  );
};

export default Presentation;
