import React from 'react';
import PropTypes from 'prop-types';
import ProductLinks from './ProductLinks';
import { useTranslation } from 'react-i18next';

const Products = ({ list }) => {
  const { t } = useTranslation();
  const productDescription = t('productDescription', { returnObjects: true });
  return (
    <div className="bg-blue-900 w-90-lg w-full mx-auto mb-8">
      <div className="py-20-xxl py-12 container px-6 mx-auto">
        <p className="w-60-lg text-white font-light text-2xl-xxl text-xl mb-8-xxl mb-6">
          {productDescription}
        </p>
        <ProductLinks list={list} />
      </div>
    </div>
  );
};

Products.propTypes = {
  list: PropTypes.array,
};

export default Products;
